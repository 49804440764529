<template>
    <script type="application/ld+json" v-html="this.snipets"></script>
</template>

<script>
export default {
    props: {
        questions: { type: Array, required: true },
    },
    data() {
        return {}
    },
    computed: {
        snipets() {
            let mainEntity = ""
            this.questions.forEach(q => {
                mainEntity += `{
                "@type": "Question",
                "name": "${this.$t(q.question)}",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${this.$t(q.answer)}"
                }
              },`
            })
            mainEntity = mainEntity.replace(/,$/, "")

            return `
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [${mainEntity}]
  }`
        },
    },
    methods: {},
}
</script>
