<template>
    <div class="plans">
        <div class="block"></div>

        <div class="container">
            <div class="grid plans-layout">
                <aside class="title-wrapper">
                    <h1 class="page-title">{{ $t("meta.faq.title") }}</h1>

                    <div class="show-xs">
                        <h3>{{ $t("pages.faq.faq") }}</h3>
                        <ul class="mobile-nav pl-0">
                            <li>
                                <a href="#faq-1">
                                    <img class="caret" src="@/assets/images/caret-down.svg" />
                                    {{ $t("pages.faq.category.general") }}
                                </a>
                            </li>
                            <li>
                                <a href="#faq-2">
                                    <img class="caret" src="@/assets/images/caret-down.svg" />
                                    {{ $t("pages.faq.category.plan_profile") }}
                                </a>
                            </li>
                            <li>
                                <a href="#faq-3">
                                    <img class="caret" src="@/assets/images/caret-down.svg" />
                                    {{ $t("pages.faq.category.data") }}
                                </a>
                            </li>
                        </ul>
                        <h3 class="mt-2">{{ $t("pages.faq.troubleshooting") }}</h3>
                        <ul class="mobile-nav pl-0">
                            <li>
                                <router-link :to="`#troubleshooting-1`">
                                    <img class="caret" src="@/assets/images/caret-down.svg" />
                                    {{ $t("pages.faq.troubleshooting") }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        </div>

        <div class="block-md hide-xs"></div>
        <div class="container body">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <div class="to-affix">
                        <div class="block-lg">
                            <h3>{{ $t("pages.faq.faq") }}</h3>
                            <ul class="plans-sidebar-nav">
                                <li>
                                    <a href="#faq-1">{{ $t("pages.faq.category.general") }}</a>
                                </li>
                                <li>
                                    <a href="#faq-2">{{ $t("pages.faq.category.plan_profile") }}</a>
                                </li>
                                <li>
                                    <a href="#faq-3">{{ $t("pages.faq.category.data") }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="block-lg">
                            <h3>{{ $t("pages.faq.troubleshooting") }}</h3>
                            <ul class="plans-sidebar-nav">
                                <li>
                                    <router-link :to="`#troubleshooting-1`">
                                        {{ $t("pages.faq.troubleshooting") }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
                <div class="grid-item plans-body next-to-affix">
                    <h2>{{ $t("pages.faq.faq") }}</h2>
                    <div class="block-md">
                        <h3 :id="`faq-1`">{{ $t("pages.faq.category.general") }}</h3>
                        <Question
                            v-for="q in this.questions.general"
                            :key="`${q.question}`"
                            :question="q.question"
                            :answer="q.answer"
                            :button="q.button"
                        />

                        <h3 :id="`faq-2`">{{ $t("pages.faq.category.plan_profile") }}</h3>
                        <Question
                            v-for="q in this.questions.plan_profile"
                            :key="`${q.question}`"
                            :question="q.question"
                            :answer="q.answer"
                            :button="q.button"
                        />

                        <h3 :id="`faq-3`">{{ $t("pages.faq.category.data") }}</h3>
                        <Question
                            v-for="q in this.questions.data"
                            :key="`${q.question}`"
                            :question="q.question"
                            :answer="q.answer"
                            :button="q.button"
                        />
                    </div>
                    <h2 :id="`troubleshooting-1`">{{ $t("pages.faq.troubleshooting") }}</h2>
                    <div class="block-md">
                        <Question
                            v-for="q in this.questions.troubleshooting"
                            :key="`${q.question}`"
                            :question="q.question"
                            :answer="q.answer"
                            :button="q.button"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="block-xl"></div>
        <FaqStructuredData :questions="this.allQuestions" />
    </div>
</template>

<script>
import Question from "@/components/Question"
import FaqStructuredData from "@/components/FaqStructuredData"
export default {
    components: { Question, FaqStructuredData },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.faq.title"),
            description: this.$i18n.t("meta.faq.description"),
        })
    },
    computed: {
        allQuestions() {
            const result = []
            Object.keys(this.questions).forEach(key => {
                this.questions[key].forEach(q => {
                    result.push(q)
                })
            })
            return result
        },
    },
    data() {
        return {
            questions: {
                general: [
                    {
                        question: "faq.01.question",
                        answer: "faq.01.answer",
                        button: null,
                    },
                    {
                        question: "faq.02.question",
                        answer: "faq.02.answer",
                        button: null,
                    },
                    {
                        question: "faq.03.question",
                        answer: "faq.03.answer",
                        button: null,
                    },
                    {
                        question: "faq.06.question",
                        answer: "faq.06.answer",
                        button: null,
                    },
                    {
                        question: "faq.08.question",
                        answer: "faq.08.answer",
                        button: null,
                    },
                    {
                        question: "faq.24.question",
                        answer: "faq.24.answer",
                        button: null,
                    },
                    {
                        question: "faq.26.question",
                        answer: "faq.26.answer",
                        button: null,
                    },
                    {
                        question: "faq.09.question",
                        answer: "faq.09.answer",
                        button: null,
                    },
                ],
                plan_profile: [
                    {
                        question: "faq.05.question",
                        answer: "faq.05.answer",
                        button: null,
                    },
                    {
                        question: "faq.15.question",
                        answer: "faq.15.answer",
                        button: null,
                    },
                    {
                        question: "faq.14.question",
                        answer: "faq.14.answer",
                        button: null,
                    },
                    {
                        question: "faq.20.question",
                        answer: "faq.20.answer",
                        button: null,
                    },
                    {
                        question: "faq.21.question",
                        answer: "faq.21.answer",
                        button: null,
                    },
                    {
                        question: "faq.23.question",
                        answer: "faq.23.answer",
                        button: null,
                    },
                    {
                        question: "faq.25.question",
                        answer: "faq.25.answer",
                        button: null,
                    },
                ],
                data: [
                    {
                        question: "faq.04.question",
                        answer: "faq.04.answer",
                        button: { install_ios: true, install_android: true },
                    },
                    {
                        question: "faq.12.question",
                        answer: "faq.12.answer",
                        button: { apn_android: true },
                    },
                    {
                        question: "faq.11.question",
                        answer: "faq.11.answer",
                        button: null,
                    },
                    {
                        question: "faq.10.question",
                        answer: "faq.10.answer",
                        button: null,
                    },
                    {
                        question: "faq.17.question",
                        answer: "faq.17.answer",
                        button: null,
                    },
                    {
                        question: "faq.28.question",
                        answer: "faq.28.answer",
                        button: null,
                    },
                    {
                        question: "faq.19.question",
                        answer: "faq.19.answer",
                        button: null,
                    },
                ],
                troubleshooting: [
                    {
                        question: "troubleshooting.01.question",
                        answer: "troubleshooting.01.answer",
                        button: null,
                    },
                    {
                        question: "troubleshooting.02.question",
                        answer: "troubleshooting.02.answer",
                        button: null,
                    },
                    {
                        question: "troubleshooting.03.question",
                        answer: "troubleshooting.03.answer",
                        button: null,
                    },
                    {
                        question: "troubleshooting.04.question",
                        answer: "troubleshooting.04.answer",
                        button: null,
                    },
                    {
                        question: "faq.22.question",
                        answer: "faq.22.answer",
                        button: null,
                    },
                ],
            },
        }
    },
}
</script>

<style scoped>
.caret {
    margin-right: 8px;
}
.title-wrapper {
    padding: 0 15px;
}
.body h2 {
    padding-top: 120px;
}
.body h2:first-child {
    padding-top: 0;
}
.body h3 {
    padding-top: 80px;
}
.body h3:first-child {
    padding-top: 0;
}
</style>
