<template>
    <div class="panel accordion block" :class="{ open: isActive }">
        <a class="panel-header" @click="openHandler">
            <h4 class="ts-bold mb-0">{{ $t(question) }}</h4>
            <img :class="['caret', { up: isActive }]" src="@/assets/images/caret-down.svg" />
        </a>
        <div class="panel-body">
            <div
                v-html="
                    $t(answer, {
                        compatibility: `<a class='ts-underline' href='/compatibility'>${$t(
                            'title.compatibility'
                        )}</a>`,
                        contact: `<a class='ts-underline' href='/contact'>${$t(
                            'label.contact_form'
                        )}</a>`,
                    })
                "
            ></div>

            <ul class="setup-links mt-3" v-if="button">
                <li v-if="button.apn_ios">
                    <i18n-link
                        :to="{ name: 'set-up-guide-ios', hash: '#APN' }"
                        class="button button-link button-small"
                        >{{ $t("pages.faq.button.apn_ios") }}</i18n-link
                    >
                </li>
                <li v-if="button.apn_android">
                    <i18n-link
                        :to="{ name: 'set-up-guide-android', hash: '#HowToUse' }"
                        class="button button-link button-small"
                        >{{ $t("pages.faq.button.apn_android") }}</i18n-link
                    >
                </li>
                <li v-if="button.install_ios">
                    <i18n-link
                        :to="{ name: 'set-up-guide-ios' }"
                        class="button button-link button-small"
                        >{{ $t("pages.faq.button.install_ios") }}</i18n-link
                    >
                </li>
                <li v-if="button.install_android">
                    <i18n-link
                        :to="{ name: 'set-up-guide-android' }"
                        class="button button-link button-small"
                        >{{ $t("pages.faq.button.install_android") }}</i18n-link
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        question: { type: String, required: true },
        answer: { type: String, required: true },
        button: { type: Object, required: false },
        dontOpen: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        openHandler() {
            if (this.dontOpen) {
                return
            }

            this.isActive = !this.isActive
        },
    },
}
</script>
