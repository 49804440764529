var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel accordion block",class:{ open: _vm.isActive }},[_c('a',{staticClass:"panel-header",on:{"click":_vm.openHandler}},[_c('h4',{staticClass:"ts-bold mb-0"},[_vm._v(_vm._s(_vm.$t(_vm.question)))]),_c('img',{class:['caret', { up: _vm.isActive }],attrs:{"src":require("@/assets/images/caret-down.svg")}})]),_c('div',{staticClass:"panel-body"},[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t(_vm.answer, {
                    compatibility: ("<a class='ts-underline' href='/compatibility'>" + (_vm.$t(
                        'title.compatibility'
                    )) + "</a>"),
                    contact: ("<a class='ts-underline' href='/contact'>" + (_vm.$t(
                        'label.contact_form'
                    )) + "</a>"),
                })
            )}}),(_vm.button)?_c('ul',{staticClass:"setup-links mt-3"},[(_vm.button.apn_ios)?_c('li',[_c('i18n-link',{staticClass:"button button-link button-small",attrs:{"to":{ name: 'set-up-guide-ios', hash: '#APN' }}},[_vm._v(_vm._s(_vm.$t("pages.faq.button.apn_ios")))])],1):_vm._e(),(_vm.button.apn_android)?_c('li',[_c('i18n-link',{staticClass:"button button-link button-small",attrs:{"to":{ name: 'set-up-guide-android', hash: '#HowToUse' }}},[_vm._v(_vm._s(_vm.$t("pages.faq.button.apn_android")))])],1):_vm._e(),(_vm.button.install_ios)?_c('li',[_c('i18n-link',{staticClass:"button button-link button-small",attrs:{"to":{ name: 'set-up-guide-ios' }}},[_vm._v(_vm._s(_vm.$t("pages.faq.button.install_ios")))])],1):_vm._e(),(_vm.button.install_android)?_c('li',[_c('i18n-link',{staticClass:"button button-link button-small",attrs:{"to":{ name: 'set-up-guide-android' }}},[_vm._v(_vm._s(_vm.$t("pages.faq.button.install_android")))])],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }